.stakingForm {
  position: relative;
  margin: 16px 0 0;
}

.stakingInfo__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  :global(.ant-space-item) {
    display: flex;
    align-items: center;
    width: 23% !important;
  }
}

.stakingInfo__item {
  width: 23%;

  &.big {
    width: 100%;
  }
}


.stakingInfo__buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 1;
}


.aside__itemTitle {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.single {
  display: flex;
}

.aside {
  width: 12.5rem;
  flex-shrink: 0;
  padding: 1rem;
  border-left: 1px solid silver;
}

.aside__inner {
  position: sticky;
  top: 0;
  padding: 10px 0;
}

.aside__item {
}

.stakingInfo {
  margin-top: 16px;
  padding: 16px 16px 40px;
  position: relative;
  flex-grow: 1;
}


.stakingInfo__actions {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 700;
  button {
    margin-right: 1rem;
  }

  &.rejected {
    color: #ff4d4f;
  }
  &.created {
    color: green;
  }
}
