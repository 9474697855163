@import "../../assets/styles/toRem";
@import "../../assets/styles/media";

.imageUploader {
  width: 48.6%;
  max-width: 450px;

  @include media-m {
    width: 100%;
  }
}

.imageUploaderInner {
}

.uploadPhoto__text {
  font-size: toRem(14);
  margin-top: toRem(20);
  color: var(--primary2);
  text-align: center;

  @include media-m {
    font-size: 0.875rem;
  }

  &.error {
    color: var(--secondary);
  }
}

.uploadPhoto__title {
  padding: 0 toRem(10);
  text-align: center;
  margin: 0;
  @include media-m {
    padding: toRem(30) toRem(10);
  }
}

.uploadPhoto__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: toRem(12);

  svg {
    width: toRem(34);
    height: toRem(28);
    stroke: var(--primary1);
  }
}


.uploadLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: toRem(150);
  background-color: var(--primary4);
  border: 1px solid var(--primary7);
  color: var(--primary1);
  border-radius: toRem(12);
cursor: pointer;
  input[type='file'] {
    display: none;
  }

  &.error {
    border-color: var(--secondary);
    color: var(--secondary);
  }

  @include media-m {
    height: toRem(120);
  }
}

.upload__inner {
  display: flex;
  align-items: center;
  height: toRem(150);
  background-color: var(--primary4);
  border: 1px solid var(--primary7);
  color: var(--primary1);
  border-radius: toRem(12);
  padding: toRem(9);

  @include media-m {
    height: toRem(120);
  }

  .upload__img {
    display: flex;
    border-radius: toRem(12);
    overflow: hidden;
    border: 1px solid var(--primary7);
    flex-shrink: 0;
    width: toRem(132);
    height: toRem(132);
    @include media-m {
      width: toRem(100);
      height: toRem(100);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

}

.upload__info {
  margin-left: auto;
}

.upload__name {
  padding: 0 1rem;
  color: var(--primary1);
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 166px);
}

.upload__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  width: toRem(34);
  height: toRem(34);
  cursor: pointer;

  svg {
    width: toRem(20);
    height: toRem(20);
    stroke: var(--primary1);
  }
}
