.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  min-height: 100vh;
}

.form {
  width: 500px;
}

.recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4000px;
  height: 200px;
}
