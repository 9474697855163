.layout {
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f2f5;
  padding-left: 25px;
  border-bottom: 1px solid #00152920;

  img {
    width: 120px;
    height: auto;
  }
}

.content {
  height: 100%;
  background-color: #F1F1F1FF;
  padding: 0 10px 10px;
  color: #000;
  overflow-y: auto;
}



.linkColumn {
  margin: 10px 0;
}

.link {
  display: block;
  color: white;
  padding: 5px 5px 5px 25px;

  &.active {
    color: #1890ff;
  }
}

.logout {
  display: flex;
  align-items: center;
}

.logoutButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  margin-left: 20px;
  cursor: pointer;
}
