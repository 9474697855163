
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0,0,0, 0.3);
}

.modal__body {
  background-color: #001529;
  border-radius: 0.625rem;
  padding: 1rem 0 1.25rem;

}

.modal__inner {
  overflow-y: auto;
  max-height: calc(100% - 4.06rem);
  padding: 1rem 3rem;
}

.modal__title {
  font-size: 1.25rem;
  color: white;
  padding: 0 1rem;
  text-align: center;
}

.modal__ok {
  display: flex;
  justify-content: center;
}

.modal__okButton {
  border: none;
  background-color: transparent;
}
