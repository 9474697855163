$media-x: 1440px;
$media-l: 1024px;
$media-m: 800px;
$media-s: 450px;


@mixin media-x {
  @media screen and (max-width: $media-x) {
    @content;
  }
}


@mixin media-l {
  @media screen and (max-width: $media-l) {
    @content;
  }
}

@mixin media-m {
  @media screen and (max-width: $media-m) {
    @content;
  }
}


@mixin media-s {
  @media screen and (max-width: $media-s) {
    @content;
  }
}

