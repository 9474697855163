:root {
  --primary: rgb(101, 213, 237);
  --primary1: rgb(174, 184, 208);
  --primary1--5: rgba(174, 184, 208, 0.5);
  --primary2: rgb(105, 115, 133);
  --primary3: rgb(32, 42, 64);
  --primary4: rgb(25, 33, 52);
  --primary5: rgb(18, 24, 39);
  --primary6: rgb(8, 16, 25);
  --primary7: rgb(81, 98, 129);
  --primary8: rgb(43, 55, 75);

  --secondary: rgb(245, 57, 50);
  --secondary1: rgb(52, 177, 11);
  --secondary2: rgb(254, 155, 31);
  --secondary4: rgb(252, 95, 95);

  --grayscale: rgb(228, 234, 245);
  --grayscale2: rgb(196, 196, 196);
  --grayscale3: rgb(0, 0, 0);
  --grayscale3--75: rgb(0, 0, 0, 0.75);
  --grayscale3--3: rgba(0, 0, 0, 0.3);

  --gradient: linear-gradient(145deg, rgba(75, 240, 228, 1) 0%, rgba(87, 204, 228, 1) 64%, rgba(85, 208, 234, 1) 100%);
  --gradient1: linear-gradient(145deg, rgba(255, 186, 0, 1) 0%, rgba(252, 95, 95, 1) 100%);

  --disabled: rgb(116, 126, 149);

  --transition: 0.3s;
}
