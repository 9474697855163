.listing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.listing__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.5rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
}


.listing__table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  padding-bottom: 20px;
  flex-grow: 1;


  th {
    background-color: white;
  }

  th, td {
    text-align: left;
    padding: 10px 20px;
  }

  tr:nth-child(even) td {
    background-color: #f2f2f2
  }

  tr:nth-child(odd) td {
    background-color: white;
  }
}

.listing__filter {
  display: flex;
  margin-bottom: 30px;
}

.listing__filterList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.listing__filterItem {
  margin-right: 10px;
  margin-bottom: 10px;
}


.listing__table_th {
  cursor: pointer;
}

.tableSort__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  &.rotate {
    transform: rotate(180deg);
  }

  img {
    width: 10px;
  }
}

.image {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
