@import "../../../assets/styles/toRem";

.listingEdit {
  padding-top: 10px;
}

.listingEdit__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 1rem;
}

.single {
  display: flex;
}

.aside {
  width: 12.5rem;
  flex-shrink: 0;
  padding: 1rem;
  border-left: 1px solid silver;
}

.aside__inner {
  position: sticky;
  top: 0;
  padding: 10px 0;
}

.aside__item {
}

.singleGroup__title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.form__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.aside__itemTitle {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.stakingInfo {
  margin-top: 16px;
  padding: 16px 16px 40px;
  position: relative;
}

.stakingInfo__buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 1;
}

.stakingInfo__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.stakingInfo__item {
  width: 23%;
  margin-bottom: 0.2rem;
}


.uploadPhoto__text {
  width: 48.8%;
  font-size: 1rem;
}

.uploadPhoto__title {
  padding: toRem(56) toRem(10);
  text-align: center;
  margin: 0;
}

.uploadPhoto__title_blue {
  font-weight: 700;
}

.uploadLabel {
  display: flex;
  justify-content: center;
  background-color: white;
  border: 2px dashed #d9d9d9;
  cursor: pointer;

  input[type='file'] {
    display: none;
  }
}

.upload__inner {
  display: flex;

  img {
    width: toRem(138);
    height: toRem(138);
    object-fit: cover;
    border-radius: 50%;
  }
}

.upload__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: toRem(50);
}

.upload__name {

}

.upload__remove {
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 6.25rem;
  min-height: 2rem;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.3S;
  border: 1px solid #f53932;
}

.proportion {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}
