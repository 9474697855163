@import "../../../assets/styles/toRem";

.inputField {
  display: flex;
  align-items: center;
  height: toRem(30);
  justify-content: space-between;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: toRem(4);
  padding: 0 toRem(8) 0 toRem(12);
  transition: border-color 0.3s ease;
  &:hover,
  &:focus {
    transition: border-color 0.7s ease;
    border-color: var(--primary);
  }
}

.input {
  width: 100%;
  border: none;
  background-color: transparent;
  //color: var(--grayscale);
  outline: none;
}
