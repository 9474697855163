html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.hidden {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

body > {
  iframe {
    display: none;
  }
}

.title {
  text-align: left;
  padding-left: 16px;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  white-space: nowrap;
}


.sticky-left {
  align-items: center;
  position: sticky;
  left: -1px;
}

.sticky-right {
  position: sticky;
  right: -1px;
}

.green {
  color: green;
}

.red {
  color: red;
}


.ant-form-item-label {
  text-align: left;
}

.ant-space-item {
  width: 48%;

  &:last-child {
    width: auto;
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}
