.comments {
  padding-top: 1rem;
  border-top: 1px solid silver;
}

.comment__list {
  margin: 15px 0 0;
  padding: 0;
  list-style-type: none;
}

.comment__item {
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
}

.comment__head {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.comment__email {
  margin-bottom: 5px;
  font-size: 14px;

}

.comment__body {
  font-size: 16px;
  color: black;
}

.comment__date {

}

.comment__empty {
  padding: 10px;
  text-align: center;
}
